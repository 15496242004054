import React, { useEffect } from 'react';
import "./MerchItem.scss";
import merchOne from "../../../assets/merch/merch-1.png"
import merchTwo from "../../../assets/merch/merch-2.png"
import merchThree from "../../../assets/merch/merch-3.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Aos from "aos"
import "aos/dist/aos.css"

const MerchItem = () => {
    useEffect(()=> {
        Aos.init({duration: 500})
    }, [])
    return (
        <div className="db-merch-items  position-relative" id="merch">
            
            <div className="custom-container d-flex align-items-center justify-content-center h-100 position-relative">
                <div>
                    <div className="row merch-item-container">
                        <div className="col-md-4" data-aos="fade-right">
                            <img src={merchOne} alt="merch-1" />
                        </div>
                        <div className="col-md-4" data-aos="fade-up">
                            <img src={merchTwo} alt="merch-2" />
                        </div>
                        <div className="col-md-4" data-aos="fade-right">
                            <img src={merchThree} alt="merch-3" />
                        </div>
                    </div>
                    <div className="db-merch-items-btn text-center">
                        <button className="btn">Get yours now <FontAwesomeIcon icon={faChevronRight} /></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MerchItem;