import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter, faYoutube, faLinkedinIn, faTiktok } from '@fortawesome/free-brands-svg-icons'
import "./FooterSocialIcons.scss"
import Aos from "aos"
import "aos/dist/aos.css"

const FooterSocialicons = () => {
    useEffect(()=> {
        Aos.init({duration: 500})
    }, [])
    return (
        <div className="footer-social-icons-container mt-4" data-aos="fade-up">
            <a className="social-icon" href="https://www.instagram.com/deshiballers/"><FontAwesomeIcon icon={faInstagram} /></a>
            <a className="social-icon" href="https://twitter.com/deshiballers"><FontAwesomeIcon icon={faTwitter} /></a>
            <a className="social-icon" href="https://www.facebook.com/deshiballers/"><FontAwesomeIcon icon={faFacebookF} /></a>
            <a className="social-icon" href="https://www.youtube.com/deshiballers"><FontAwesomeIcon icon={faYoutube} /></a>
            <a className="social-icon" href="https://bd.linkedin.com/company/deshiballers"><FontAwesomeIcon icon={faLinkedinIn} /></a>
            <a className="social-icon" href="https://www.tiktok.com/@deshiballers"><FontAwesomeIcon icon={faTiktok} /></a>
        </div>
    );
};

export default FooterSocialicons;