import React, { useEffect } from 'react';
import "./About.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import arrowRight from "../../assets/icons/arrow-right.svg"
import teamImg from "../../assets/about-team.png"
import playerOutline from "../../assets/player-outline.svg"
import Aos from "aos"
import "aos/dist/aos.css"

const About = () => {
    useEffect(()=> {
        Aos.init({duration: 700})
    }, [])
    return (
        <section className="db-about-section">
            <div className="row h-100">
                <div className="col-lg-1 col-md-1 col-sm-0"></div>
                <div className="col-lg-11 col-md-11 col-sm-12">
                    <div className="row h-100">
                        <div className="col-lg-5 about-content-container d-flex align-items-center justify-content-lg-start justify-content-md-start justify-content-sm-center" data-aos="fade-right">
                            <div className="about-content">
                                <h2>what we do</h2>
                                <p>‘Deshi Ballers’ is a female-led sports development
                                    organization working to create a platform for Bangladeshi women and girls to be empowered through basketball, with training camps, tournaments, leagues, outreach programs and grooming of future female sports leaders.
                                </p>
                                <p>‘Deshi Ballers’ works to empower and inspire females in
                                    Bangladesh to play sports and take the skills learned
                                    through sports to become leaders in other spheres of life.
                                </p>
                                <div className="mt-5">
                                    <a href="https://www.thedailystar.net/supplements/29th-anniversary-supplements/the-youth-the-era-digitalisation/news/dribbling-towards-success-1870135?amp&fbclid=IwAR2CQxyfwMg3RUcguklBAZlU11LPGyMifQDBD3AecOQCkaX4jIDlPCS5nSI">learn more <FontAwesomeIcon icon={faChevronRight} /></a>
                                    <span className="arrow-container">
                                        <img src={arrowRight} alt="arrow-right" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="row h-100">
                                <div className="col-lg-12 about-outline-container" data-aos="fade-up">
                                    <img src={playerOutline} alt="player-outline" />
                                </div>
                                <div className="col-lg-12 about-team-container" data-aos="fade-right">
                                    <img src={teamImg} alt="deshiballerz-team" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;