import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter, faYoutube, faLinkedinIn, faTiktok } from '@fortawesome/free-brands-svg-icons'
import "./Socialicons.scss"
import { motion } from 'framer-motion';

const Socialicons = () => {
    return (
        <motion.div animate={{ opacity: 1 }} transition={{ ease: "easeOut", duration: 2 }} initial={{opacity: 0}}  className="social-icons-container">
            <a className="social-icon" href="https://www.instagram.com/deshiballers/"><FontAwesomeIcon icon={faInstagram} /></a>
            <a className="social-icon" href="https://twitter.com/deshiballers"><FontAwesomeIcon icon={faTwitter} /></a>
            <a className="social-icon" href="https://www.facebook.com/deshiballers/"><FontAwesomeIcon icon={faFacebookF} /></a>
            <a className="social-icon" href="https://www.youtube.com/deshiballers"><FontAwesomeIcon icon={faYoutube} /></a>
            <a className="social-icon" href="https://bd.linkedin.com/company/deshiballers"><FontAwesomeIcon icon={faLinkedinIn} /></a>
            <a className="social-icon" href="https://www.tiktok.com/@deshiballers"><FontAwesomeIcon icon={faTiktok} /></a>
        </motion.div>
    );
};

export default Socialicons;