import React, { useEffect } from 'react';
import "./Awards.scss";
import awardBanner from "../../assets/award-banner.png";
import arrowLeft from "../../assets/icons/arrow-left-violate.svg"
import Aos from "aos"
import "aos/dist/aos.css"

const Awards = () => {
    useEffect(()=> {
        Aos.init({duration: 500})
    }, [])
    return (
        <section className="db-awards-section mt-5">
            <div className="row h-100 position-relative flex-lg-row flex-column-reverse">
                <div className="col-md-8 db-awards-section-content" data-aos="fade-right">
                    <div className="award-container h-100">
                        <div className="row h-100">
                            <div className="col-md-6 award-image-container position-relative">
                                <img src={awardBanner} alt="award-banner" />
                            </div>
                            <div className="col-md-6 d-flex justify-content-center align-items-center award-text-container">
                                <div className="ps-5 text-white mt-5">
                                    <p>Co-Founder of Deshi Ballers Gulnahar Mahbub Monika has received the prestigious Diana Award, established in the memory of Diana, Princess of Wales, for the year 2021!</p>
                                    <p>This award is an incredible achievement for our organization, and we hope to do justice to this recognition by working diligently towards empowering more women in sports and providing them with better opportunities. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex align-items-center justify-content-end position-relative" data-aos="fade-right">
                    <div className="orange-box"></div>
                    <div className="text-end awards-content-container">
                        <h2><span className="award-content-arrow"><img src={arrowLeft} alt="arrow-left" /></span>our</h2>
                        <h2>awards</h2>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Awards;
