import React from 'react';
import Navigation from '../Navgation/Navigation';
import "./Header.scss";
import Headeline from './Headline/Headeline';
import Socialicons from './Socialicons/Socialicons';
import dbLogoMobile from "../../assets/logo.png";


const Header = () => {
    return (
        <section className="db-header-section" id="header">
            <div className="custom-container">
                <div className="db-header-container">
                    <Navigation/>
                    <Headeline/>
                </div>
            </div>
            <Socialicons/>
            <div className="db-mobile-logo">
                <img src={dbLogoMobile} alt="db-mobile" />
            </div>
        </section>
    );
};

export default Header;