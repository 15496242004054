import About from "./Components/About/About";
import Events from "./Components/Events/Events";
import Header from "./Components/Header/Header";
import Merch from "./Components/Merch/Merch";
import "./App.scss";
import Awards from "./Components/Awards/Awards";
import Subscribe from "./Components/Subscribe/Subscribe";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Header/>
      <About/>
      <Events/>
      <Merch/>
      <Awards/>
      <Subscribe/>
      <Footer/>
    </div>
  );
}

export default App;
