import React, { useEffect } from 'react';
import "./Events.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import arrowRightOrange from "../../assets/icons/arrow-right-orange.svg"
import Aos from "aos"
import "aos/dist/aos.css"

const Events = () => {
    useEffect(()=> {
        Aos.init({duration: 500})
    }, [])
    return (
        <section className="db-events-section" id="events">
            <div className="custom-container h-100">
                <div className="db-events">
                    <div className="row h-100">
                        <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center db-events-headline" data-aos="fade-right">
                            <div className="text-end">
                                <h2 className="events-heading">events</h2>
                                <h2 className="events-baller-heading">deshi ballers</h2>
                                <img src={arrowRightOrange} alt="arrow-right-orange" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 db-events-content" data-aos="fade-right">
                            <div className="w-75">
                                <p>
                                    Deshi Ballers talks to women of all ages to not only take part in the sport of basketball. But we also encourage them to take part in all sports and  stand up for themselves when facing any discrimination.Our messages are not only for the women but also for all genders who can help facilitate the change and stand behind our cause.
                                </p>
                                <div className="mt-4">
                                    <button className="btn"><a href="https://www.facebook.com/deshiballers/events/?ref=page_internal">Browse events <FontAwesomeIcon icon={faChevronRight} /></a></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Events;