import { motion } from 'framer-motion';
import React from 'react';

const Headeline = () => {
    return (
        <motion.div animate={{ y: 0, opacity: 1 }} transition={{ ease: "easeOut", duration: 0.5 }} initial={{y: 100, opacity: 0}} className="db-header-content">
            <h1>the court needs her</h1>
        </motion.div>
    );
};

export default Headeline;