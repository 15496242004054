import React, { useRef, useState } from 'react';
import "./Navigation.scss"
import dbLogo from "../../assets/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars,faTimes } from '@fortawesome/free-solid-svg-icons'

const Navigation = () => {
    const [isClicked, setIsClicked] = useState(false)
    const mobileMenu = useRef(null)
    // const mobileMenu = document.getElementById("mobileMenu")
    const handleMenuClick = () => {
        mobileMenu.current.style.left = "0"
        setIsClicked(true)
    }
    const handleCloseClick = () => {
        mobileMenu.current.style.left = "-50%"
        setIsClicked(false)
    }
    return (
        <div>
            <nav className="db-nav-bar text-center" ref={mobileMenu}>
                <a href="#header">Home</a>
                <a href="#events">Events</a>
                <a href="/"><img src={dbLogo} alt="db-logo" id="db-logo" /></a>
                <a href="#merch">Merch</a>
                <a href="#contact">Contact</a>
                <div className="db-menu-icon">
                    {isClicked ? <FontAwesomeIcon icon={faTimes} onClick={handleCloseClick} /> : <FontAwesomeIcon icon={faBars} onClick={handleMenuClick} />}
                </div>
            </nav>
        </div>
    );
};

export default Navigation;