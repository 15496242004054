import React, { useEffect } from 'react';
import "./MerchHeader.scss";
import zigzagOrange from "../../../assets/zig-zag/zig-zag-orange.png";
import arrowGreen from "../../../assets/icons/arrow-down-green.svg";
import orangeBall from "../../../assets/icons/big-orange-ball.svg";
import merchHeader from "../../../assets/merch-banner.png";  
import Aos from "aos"
import "aos/dist/aos.css"

const MerchHeader = () => {
    useEffect(()=> {
        Aos.init({duration: 500})
    }, [])
    return (
        <div className="db-merch-header position-relative">
            <div className="orange-ball">
                <img src={orangeBall} alt="orange-ball" />
            </div>
            <div className="custom-container h-100 position-relative">
                <div className="row h-100">
                    <div className="col-lg-6 col-md-6 col-sm-12 merch-header-banner" data-aos="fade-up">
                        <img src={merchHeader} alt="merch-header" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 merch-header-footer position-relative" data-aos="fade-up">
                        <div className="merch-header-footer-image">
                            <img src={zigzagOrange} alt="zig-zag-orange" />
                        </div>
                        <div className="merch-header-footer-content h-100 position-relative">
                            <div className="merch-header-footer-dist">
                                <h2>new</h2>
                                <h2><span className="green-arrow-down"><img src={arrowGreen} alt="arrow-green" /></span>merch</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MerchHeader;