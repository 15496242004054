import React from 'react';
import MerchHeader from './MerchHeader/MerchHeader';
import MerchItem from './MerchItem/MerchItem';

const Merch = () => {
    return (
        <section className="db-merch-section">
            <MerchHeader/>
            <MerchItem/>
        </section>
    );
};

export default Merch;