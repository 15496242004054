import React, { useEffect, useRef, useState } from 'react';
import "./Subscribe.scss";
import gameImg from "../../assets/game-sign-up.png"
import zigzagViolate from "../../assets/zig-zag/zig-zag-violate.png"
import emailjs from 'emailjs-com';
import Aos from "aos"
import "aos/dist/aos.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons'

const Subscribe = () => {
    const form = useRef();
    const cross = useRef(null);
    const [isSend, setIsSend] = useState(false)
    const sendMail = e => {
        e.preventDefault()
        emailjs.sendForm("service_q5593v1", "template_zqn6sfg", form.current, "user_LIzOnD0MosYW0ZUIvhDSW")
            .then(res => {
               setIsSend(true) 
            })
            .catch(err => console.log(err))
    }
    useEffect(()=> {
        Aos.init({duration: 500})
    }, [])
    const handleCross = e => {
        e.preventDefault()
        cross.current.parentElement.style.transform = "translateX(-300%)"
        form.current.reset()
        setIsSend(false)
    }
    return (
        <section className="db-subscribe-section" id="contact">
            <div className="row h-100">
                <div className="col-md-8 py-5 d-flex justify-content-center align-items-center subscribe-form position-relative" data-aos="fade-right">
                    <div className="orange-box"></div>
                    {isSend && <div className="push-notification">
                        <div className="cross text-end" ref={cross} onClick={handleCross}><FontAwesomeIcon icon={faTimes} /></div>
                        <div className="text-center">
                            <h2>Thank you!</h2>
                            <p>we have received your query!</p>
                        </div>
                    </div>}
                    <div className="violate-zig-zag">
                        <img src={zigzagViolate} alt="zig-zag-violate" />
                    </div>
                    <div className="subscribe-form-content">
                        <h2>contact us</h2>
                        <p>Join us on and off the court for our events. Register as a player or get involved with our amazing activities!</p>
                        <form className="mt-4" onSubmit={sendMail} ref={form}>
                            <div className="mt-3">
                                <input type="text" name="name" placeholder="Full Name" required/>
                            </div>
                            <div className="mt-3">
                                <input type="text" name="subject" placeholder="Subject" required/>
                            </div>
                            <div className="mt-3">
                                <input type="email" name="email" placeholder="E-mail Address" required/>
                            </div>
                            <div className="mt-3">
                                <textarea type="text" name="message" placeholder="Your Message" required></textarea>
                            </div>
                            <div>
                                <button type="submit">Send Now! <FontAwesomeIcon icon={faPaperPlane} /></button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-4 subscribe-form-image p-0" data-aos="fade-right">
                    <img src={gameImg} alt="game-play"/>
                </div>
            </div>
        </section>
    );
};

export default Subscribe;