import React, { useEffect } from 'react';
import FooterSocialicons from './FooterSocialIcons';
import "./Footer.scss";
import logoBrand from "../../assets/logo.png"
import whiteBall from "../../assets/icons/big-white-ball.svg"
import Aos from "aos"
import "aos/dist/aos.css"

const Footer = () => {
    useEffect(()=> {
        Aos.init({duration: 500})
    }, [])
    return (
        <footer className="db-footer-section d-flex justify-content-center align-items-center position-relative">
            <div className="footer-white-ball" data-aos="fade-right">
                <img src={whiteBall} alt="white-ball" />
            </div>
            <div className="db-footer-section-content">
                <div className="row h-100 d-flex align-items-center flex-lg-row flex-sm-column">
                    <div className="col-lg-4 col-md-4 col-sm-6" data-aos="fade-right">
                        <a href="#header"><img src={logoBrand} alt="basketball-brand" /></a>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="text-center">
                            <h5 data-aos="fade-up">connect</h5>
                            <FooterSocialicons/>
                            <p>© Deshi Ballers. All rights reserved.</p>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;